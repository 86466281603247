import SettingsAPIBeta from "pages/Settings/SettingsAPIBeta";
import React from "react";

const APITab = () => {
  return (
    <div className="p-[20px]">
      <SettingsAPIBeta />
    </div>
  );
};

export default APITab;
